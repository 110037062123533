<template>
    <div class="record-num">

        <a :href="ICPLink" target="_blank">{{ ICPText }}</a>
        <span>&nbsp;{{ company }}</span>
        <div class="image-wrap"><img class="image" src="~assets/gongan.png"></div>
        <a :href="recordcodeLink" target="_blank">&nbsp;{{ recordcode }}</a>
    </div>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: [],
        data () {
            return {
                ICPText: '',
                ICPLink: '',
                company: '',
                recordcode: '',
                recordcodeLink: ''
            }
        },
        computed: {},
        methods: {},
        created() {
            this.ICPText = this.$store.state.config.ICPText
            this.ICPLink = this.$store.state.config.ICPLink
            this.company = this.$store.state.config.company
            this.recordcode = this.$store.state.config.recordcode
            this.recordcodeLink = this.$store.state.config.recordcodeLink
        }
    }
</script>
<style scoped lang="less">
.record-num {
    height: 41px;
    line-height: 41px;
    font-size: 12px;
    font-weight: 400;
    color: #919191;
    a {
        line-height: 41px;
        color: inherit;
    }
    .image-wrap {
        display: inline-block;
        margin-left: 3px;
        vertical-align: middle;
        .image {
            display: block;
            width: 16px;
        }
    }
} 
</style>