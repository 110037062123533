import cookies from 'vue-cookies';
import ViewUI from 'view-design';

export const rename = {
	methods: {
		showElement(e, rowid=undefined, fontSize=14, color="#5a6875", len=32) {
            if (e.currentTarget.querySelector('input')) return
            var _this = this
            var currentTarget = e.currentTarget
            var element = e.currentTarget.querySelector('.name')
            var oldText = element.innerText
            var icon = e.currentTarget.querySelector('.icon')
            //创建新的input元素
            var newobj = document.createElement('input');
            //去除语法检测
            newobj.spellcheck = false;

            //增加类名
            newobj.setAttribute('class', 'input')
            newobj.style = `width: 100%; font-size: ${fontSize}px;color: ${color};`
            //为新增元素添加类型
            newobj.type = 'text';
            //为新增元素添加value值
            newobj.value = oldText;
            //为新增元素添加光标离开事件
            newobj.onblur = function() {
                //清空
                currentTarget.innerHTML = ''
                //当触发时判断新增元素值是否为空\去除头尾空格，为空则不修改，并返回原有值
                this.value = this.value.replace(/(^\s*)|(\s*$)/g, "")
                if (this.value.length > len) {
                    ViewUI.Message.error(`输入不能超过${len}个字符`)
                }
                element.innerText = (this.value === oldText || this.value === '' || this.value.length > len) ? oldText : this.value;
                //恢复原有元素
                currentTarget.appendChild(element)
                currentTarget.appendChild(icon)

                if (this.value !== oldText && this.value !== '' && this.value.length <= len) {
                    _this.handleRename(element.innerText, rowid)
                }
            }
            //添加enter事件
            newobj.onkeypress = function(e) {
                if (e.keyCode === 13) {
                    this.blur()
                }
            }
            //清空
            currentTarget.innerHTML = ''
            //添加该标签的子节点，input对象
            currentTarget.appendChild(newobj)
            //设置选择文本的内容或设置光标位置（两个参数：start,end；start为开始位置，end为结束位置；如果开始位置和结束位置相同则就是光标位置）
            newobj.setSelectionRange(0, oldText.length);
            //设置获得光标
            newobj.focus();
        }
	}
}

export const checkbox = {
    methods: {
        handleCheckAll () {
            if (this.indeterminate) {
                this.checkAll = false;
            } else {
                this.checkAll = !this.checkAll;
            }
            this.indeterminate = false;

            if (this.checkAll) {
                //全选当前页
                let set = new Set(this.checkAllGroup)
                this.list.map((item)=> {
                    if (!set.has(item.rowid)) {
                        set.add(item.rowid)
                    }
                })
                this.checkAllGroup = Array.from(set)
            } else {
                this.checkAllGroup = [];
            }
        },
        checkAllGroupChange (data) {
            let isAll = true
            let set = new Set(data)
            this.list.map((item)=>{
                if (!set.has(item.rowid)) {
                    isAll = false
                }
            })

            this.checkAllGroup = data

            if (data.length > 0) {
                this.indeterminate = true;
                this.checkAll = false;
                //当前页全勾选，显示全选状态
                if (isAll) {
                    this.indeterminate = false;
                    this.checkAll = true;
                }
            } else {
                this.indeterminate = false;
                this.checkAll = false;
            }
        }
    }
}

export const dropdownTree = {
    methods: {
        handleSelectChange(e) {
            this.nodeList = e
            this.data = this.clearStatus(this.data, ['checked', 'indeterminate'])
            this.visible = false
        },
        //清除勾选或选中状态
        clearStatus(obj, keys) {
            let newObj = obj.constructor === Array ? []:{};
            if(typeof obj !== 'object'){
                return
            }else{
                for(let i in obj){
                    if(Object.prototype.hasOwnProperty.call(obj, i)){
                        if (typeof obj[i] !== 'object') {
                            newObj[i] = obj[i]
                            if (keys.indexOf(i) > -1) newObj[i] = false
                        } else {
                            newObj[i] = this.clearStatus(obj[i], keys)
                        }
                    }
                }
            }
            return newObj
        },
        handleTree(e) {
            this.nodeList = e
            this.data = this.clearStatus(this.data, ['selected'])
        },
        handleRecursive(array) {
            let arr = []
            for (var i = 0; i < array.length; i++) {
                let obj = {}

                obj.title = array[i].name
                obj.expand = true
                for (let k in array[i]) {
                    if (Object.prototype.hasOwnProperty.call(array[i], k)) {
                        obj[k] = array[i][k].constructor !== Array ? array[i][k] : this.handleRecursive(array[i][k])
                    }
                }
                arr.push(obj)
            }
            return arr
        },
        clearNodeList() {
            this.nodeList = []
            this.data  = this.handleRecursive(this.tree_data)
        },
        handleTreeDropdown(e) {
            if (!e) {
                this.searchNew()
            }
        },
        getTreeList(obj, key) {
            if (typeof obj !== 'object'){
                return
            } else {
                if (obj.constructor === Object) {
                    this.treeList.push(obj[key])
                    if (obj.children && obj.children.length) this.getTreeList(obj.children, key)
                } else {
                    for(let i in obj) {
                        if (Object.prototype.hasOwnProperty.call(obj, i)) {
                            this.getTreeList(obj[i], key)
                        }
                    }
                }
            }
        }
    }
}

export const tree = {
    methods: {
        handleTree(e, name) {
            if (name === 'department') {
                if (e.length === 0) {
                    this.departmentInfo = {
                        title: '',
                        department_id: ''
                    }
                } else {
                    this.departmentInfo = {
                        department_id: e[0].department_id,
                        title: e[0].title
                    }
                    this.formValidate.department_id = e[0].department_id
                    this.$refs['department'].hideMenu()
                }
            } else {
                if (e.length === 0) {
                    this.addressDepartmentInfo = {
                        title: '',
                        address_department_id: ''
                    }
                } else {
                    this.addressDepartmentInfo = {
                        address_department_id: e[0].department_id,
                        title: e[0].title
                    }
                    this.formValidate.address_department_id = e[0].department_id
                    this.$refs[name].hideMenu()
                }
            }
        },
        handleRecursive(array, key) {
            let arr = []
            for (var i = 0; i < array.length; i++) {
                let obj = {}

                //获取相应key节点数据
                if (key === array[i].department_id) {
                    this.nodeData = {
                        department_id: array[i].department_id,
                        title: array[i].name
                    }
                    obj.selected = true
                } else {
                    obj.selected = false
                }
                obj.title = array[i].name
                obj.expand = true
                for (let k in array[i]) {
                    if (Object.prototype.hasOwnProperty.call(array[i], k)) {
                        obj[k] = array[i][k].constructor !== Array ? array[i][k] : this.handleRecursive(array[i][k], key)
                    }
                }
                arr.push(obj)
            }
            return arr
        }
    }
}

export const login = {
    methods: {
        goToPerson() {
            if (this.isRemember) {
                localStorage.setItem('account', 'person')
            } else {
                localStorage.removeItem('account')
            }
            this.$store.dispatch('RESET_ROUTES')
            this.$store.commit('SET_ORGANIZATION_NAME', '')
            this.$router.replace({ name: 'personSpaces' })
        },
        goToCompany(id, name) {
            if (this.isRemember) {
                localStorage.setItem('account', 'company')
                localStorage.setItem('organization_id', id)
            } else {
                localStorage.removeItem('organization_id')
                localStorage.removeItem('account')

            }
            this.$store.dispatch('RESET_ROUTES')
            this.$store.commit('SET_ORGANIZATION_NAME', name)
            this.$store.commit('SET_ORGANIZATION_ID', id)
            return this.$api.put('auth/access_token', { organization_id: id }).then(({ data }) => {
                cookies.set('Authorization', data.token)
                window.localStorage.setItem('token',data.token);
                window.localStorage.setItem('username', data.account);
                this.$store.dispatch('GET_BUCKET_TOKENS').then(() => {
                    this.$store.dispatch('GET_ACCESS_RIGHTS').then(() => {
                        if (this.$store.state.rolesList.indexOf('content') > -1) {
                            this.$router.replace({ name: 'companySpaces' })
                        } else {
                            this.$router.replace({ name: 'mediaScreens' })
                        }
                    })
                }).catch((err) => {
                    console.log('err:', err)
                })
            })
        },
        getOrganizationList() {
            return this.$api.get('organizations/search_for_user_login').then(({ data }) => {
                // this.organizationlist = data
                return data
            }).catch(() => {
                return []
            })
        }
    }
}

export const navigation = {
    methods: {
        goTo(name) {
            this.$router.push({ name: name })
        },
        goTo2(name) {
            let permissionList = this.$store.state.rolesList
            if (permissionList.length && permissionList.indexOf('device') > -1) {
                name = 'mediaScreens'
            }
            this.$router.push({ name: name })
        },
        goTo3(name) {
            let permissionList = this.$store.state.rolesList
            if (!permissionList.length) return
            if (permissionList.indexOf('account') === -1) {
                name = 'employee'
            } else {
                name = 'setting'
            }
            this.$router.push({ name: name })
        }
    }
}